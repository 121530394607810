export default {
  mounted () {
    let componentName = this.snakeToCamel(this.$options.name)
    if (typeof this[componentName] === 'function') {
      this[componentName]()
    }
  },
  methods: {
    snakeToCamel (string) {
      return string.replace(
        /([-_][a-z])/g,
        (group) => group.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      )
    },
    aRadio () {
      if (this.type !== 'lights' && this.options.find((item) => item.color)) {
        this.$set(this, 'type', 'lights')
      }
    }
  }
}
