<template>
  <div v-show="!isHidden" :style="computedStyle" :class="CSSClasses">
    <el-radio-group
      v-if="type === 'basic' || type === 'button'"
      @change="$emit('input', localModel)"
      :disabled="_isReadonly"
      :name="name"
      :style="this.CSS"
      :size="size"
      v-model="localModel"
    >
      <component
        v-for="(item, index) in computedOptions"
        :key="index"
        :label="item.value"
        :is="type === 'basic' ? 'el-radio' : 'el-radio-button'"
      >{{ item.name }}</component>
    </el-radio-group>
    <el-radio-group v-else-if="type === 'lights'" :disabled="_isReadonly" :name="name" :style="this.CSS + ';width:100%'">
      <template v-for="(item, index) in computedOptions">
        <el-tooltip
          v-if="item.tooltip"
          class="item"
          effect="dark"
          :key="index"
          :content="item.tooltip"
        >
          <el-button
            circle
            size="mini"
            :style="{background: item.color}"
            @click="localModel = item.value, $emit('input', item.value)"
            :label="item.value"
          ></el-button>
        </el-tooltip>
        <el-button
          v-else
          circle
          size="mini"
          :style="{background: item.color}"
          :key="index"
          @click="localModel = item.value, $emit('input', item.value)"
          :label="item.value"
        ></el-button>
      </template>
    </el-radio-group>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import legacyMixin from '@/components/InterfaceEditor/components/legacyMixin'
import { isNumeric } from '@/helpers'

export default {
  name: 'a-radio',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, legacyMixin, VisibleMixin],
  props: {
    value: {
      frozen: true
    },
    model: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    defaultValue: {
      type: String,
      description: 'default',
      options: {
        tooltip: {
          show: true,
          content: 'default_name'
        }
      }
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    width: {
      type: String,
      description: 'width'
    },
    block: {
      type: Boolean,
      description: 'full_line',
      default: true
    },
    type: {
      type: String,
      description: 'view',
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'lights', name: 'Светофор' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },
    size: {
      type: String,
      description: 'size',
      editor: 'Size'
    },
    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },
    options: {
      type: Array,
      default () {
        return [{ name: 'Лэйбл', value: 'Значение' }]
      },
      editor: 'Options'
    }
  },
  data () {
    return {
      localModel: this.value || this.defaultValue,
      localOptions: this.options
    }
  },
  computed: {
    lights () {
      let lights = false
      this.options.forEach(element => {
        if (element.color) lights = true
      })
      return lights
    },
    computedOptions () {
      let result = null
      // убрать копирование по ссылке
      try {
        result = JSON.parse(JSON.stringify(this.localOptions))
      } catch (error) {
        console.log({ error })
      }

      if (!Array.isArray(result)) return this.localOptions

      result = result.map(element => {
        if (element.value === '{{user_id}}') {
          element.value = this.$store.getters['Authorization/userId']
        }
        if (element.value === '{{role_id}}') {
          element.value = this.$store.getters['Authorization/roleId']
        }

        return element
      })

      return result
    },
    computedStyle () {
      let css = ''

      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    value (val) {
      if ((this.localModel || '').toString() !== (val || '').toString()) {
        this.localModel = val + ''
        this.parseValue()
      }
    }
  },
  created () {
    this.parseValue()
  },
  mounted () {
    if (this.localModel) {
      this.$emit('input', this.localModel)
    }
  },
  methods: {
    parseValue () {
      // 0 || ''--> ''
      // Чтобы избежать случая с нулем, делаем проверка isNumeric
      let parsed = this.localModel || this.value
      if (isNumeric(this.localModel)) {
        parsed = this.localModel
      }
      try {
        parsed = JSON.parse(this.localModel)
      } catch (error) {}
      if (parsed instanceof Array && parsed.length > 0) {
        this.localModel = parsed[0].id + ''
      }
      if (typeof parsed === 'number') {
        this.localModel = parsed + ''
      }
      if (parsed === '{{user_id}}') {
        this.localModel = this.$store.getters['Authorization/userId']
      }
      if (parsed === '{{role_id}}') {
        this.localModel = this.$store.getters['Authorization/roleId']
      }
      // если значение пришло от логического поля
      if (typeof parsed === 'boolean') {
        this.localModel = parsed + ''
      }
      console.log(parsed, this.localModel)
    }
  }
}
</script>

<style scoped>
</style>
